import { breakpoints, device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import styled, { css } from 'styled-components'

import { PlansProps } from './types'

export const ContentHeader = styled.section`
  min-height: 100vh;
  position: relative;
  align-items: initial;

  @media ${device.tablet} {
    align-items: center;
  }

  .btn {
    height: 48px;
  }

  .sora {
    font-family: "Sora", Helvetica, sans-serif;
  }

  .tabs {
    @media (min-width: ${breakpoints.md}) {
      min-height: 531px;
    }

    ul {
      justify-content: center;
      margin-bottom: 24px;

      li {
        &:first-child {
          margin-right: 12px;
        }
        &:last-child {
          margin-right: 12px;
        }

        button {
          width: 156px;
          text-align: center;

          @media (min-width: ${breakpoints.md}) {
            width: 348px;
          }
          @media (min-width: ${breakpoints.md}) {
            width: 270px;
          }
        }
      }
    }
  }
`
export const Card = styled.div`
  margin-top: 48px;
  min-height: 684px;
  background: ${orange.extra};
  border-radius: 16px;
  padding: 16px 4px 4px;
`

export const CardWrapper = styled.div<PlansProps>`
  box-shadow: 0px 4px 40px rgba(182, 172, 164, 0.25);
  border-radius: 16px;
  margin-top: 28px;

  ${({ bestSeller }: PlansProps) => bestSeller && css`
      box-shadow: 0px 4px 40px rgba(255, 122, 0, 0.25);
      margin-top: 0;
  `}

  & + * {
    margin-left: 20px;
  }

  .text-line-through {
    text-decoration: line-through;
  }
`

export const CardHeader = styled.div`
  background: ${white};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom: 1px solid ${grayscale[200]};
  padding-top: 25px;
  padding-bottom: 25px;
  margin-top: 10px;

  @media ${device.tablet} {
    height: 269px;
  }

  @media ${device.desktopXL} {
    height: 253px;
  }

  .icons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const CardBody = styled.div`
  background: ${white};
  border-bottom: 1px solid ${grayscale[200]};
  padding: 25px 20px 32px 26px;
  text-align: left;

  @media ${device.desktopXL} {
    padding-bottom: 45px;
  }

  .body-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    svg {
      margin-right: 16px;
    }
  }
`

export const BannerGourmet = styled.div`
  padding: 4px 8px;
  background: #07605B;
  color: ${grayscale[100]};
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;

  @media ${device.desktopXL} {
    margin-top: -23px;
  }
`

export const CardFooter = styled.div`
  background: rgba(245, 246, 250);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 25px 16px;
  border: 1px solid ${grayscale[200]};

  @media ${device.tablet} {
    height: 198px;
  }

  @media ${device.desktopXL} {
    height: 192px;
  }

  .success {
    color: #00AA4F;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .old-price {
    font-family: "Sora", Helvetica, sans-serif;
    text-decoration: line-through;
  }
`
